<template>
    <div>
        <div>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--8">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('transaction_history')}}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <el-dropdown trigger="click" class="float-right mr-2">
                                            <span class="el-dropdown-link btn btn-dark btn-sm">
                                                View<i class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <div class="container">
                                                <base-checkbox
                                                    v-model="checked.material_number"
                                                    class="mb--1"
                                                >
                                                    {{ tt("material_number") }}
                                                </base-checkbox>
                                                <base-checkbox v-model="checked.plant" class="mb--1">
                                                    {{ tt("plant") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.site"
                                                    class="mb--1"
                                                >
                                                    {{ tt("site") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.purchase_requisition"
                                                    class="mb--1"
                                                >
                                                    {{ tt("purchase_requisition") }}
                                                </base-checkbox>
                                                <base-checkbox v-model="checked.pr_date" class="mb--1">
                                                    {{ tt("requisition_date") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.purchase_order"
                                                    class="mb--1"
                                                >
                                                    {{ tt("purchase_order") }}
                                                </base-checkbox>
                                                <base-checkbox v-model="checked.pd_date" class="mb--1">
                                                    {{ tt("promise_delivery_date") }}
                                                </base-checkbox>
                                                <base-checkbox v-model="checked.vendor" class="mb--1">
                                                    {{ tt("vendor") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.material_description"
                                                    class="mb--1"
                                                >
                                                    {{ tt("material_text") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.short_description"
                                                    class="mb--1"
                                                >
                                                    {{ tt("short_text") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.unit_price"
                                                    class="mb--1"
                                                >
                                                    {{ tt("unit_price") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.currency_pr"
                                                    class="mb--1"
                                                >
                                                    {{ tt("currency_pr") }}
                                                </base-checkbox>
                                                 <base-checkbox
                                                    v-model="checked.unit_price_po"
                                                    class="mb--1"
                                                >
                                                    {{ tt("unit_price_po") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.currency_po"
                                                    class="mb--1"
                                                >
                                                    {{ tt("currency_po") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.po_qty"
                                                    class="mb--1"
                                                >
                                                    {{ tt("po_qty") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.uom"
                                                    class="mb--1"
                                                >
                                                    {{ tt("uom") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.manufacturer"
                                                    class="mb--1"
                                                >
                                                    {{ tt("manufacturer") }}
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="checked.gr_date"
                                                    class="mb--1"
                                                >
                                                    {{ tt("gr") }}
                                                </base-checkbox>
                                                </div>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <base-button @click="pivot" class="float-right mr-2" type="dark" size="sm" :disabled="load.transaction_history">{{tt('pivot')}} <span v-if="load.transaction_history"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!table.load">
                                <div style="height:350px;overflow-y:auto">
                                    <el-table class="table-responsive table" :data="table.data" header-row-class-name="thead-light" border style="width: 100%" height="450px" v-if="table.data.length > 0">
                                        <el-table-column :label="tt('material')" v-if="checked.material_number" sortable width="145px" prop="material_number">
                                
                                        </el-table-column>
                                        <el-table-column :label="tt('plant')" v-if="checked.plant" sortable width="125px" prop="plant">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('site')" v-if="checked.site" sortable min-width="125px" prop="site">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('purchase_requisition')" v-if="checked.purchase_requisition" sortable min-width="125px" prop="purchase_requisition">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('requisition_date')" v-if="checked.pr_date" sortable min-width="125px" prop="pr_date">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('purchase_order')" v-if="checked.purchase_order" sortable min-width="125px" prop="purchase_order">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('purchase_order_date')" v-if="checked.po_date" sortable min-width="125px" prop="po_date">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('promise_delivery_date')" v-if="checked.pd_date" sortable min-width="125px" prop="pd_date">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('vendor')" v-if="checked.vendor" sortable min-width="125px" prop="vendor">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('material_text')" v-if="checked.material_description" sortable min-width="165px" prop="material_description">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('short_text')" v-if="checked.short_description" sortable min-width="155px" prop="short_description">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('unit_price_pr')" v-if="checked.unit_price" sortable min-width="165px" prop="unit_price">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('currency_pr')" v-if="checked.currency_pr" sortable min-width="165px" prop="currency_pr">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('unit_price_po')" v-if="checked.unit_price_po" sortable min-width="165px" prop="unit_price_po">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('currency_po')" v-if="checked.currency_po" sortable min-width="165px" prop="currency_po">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('po_qty')" v-if="checked.po_qty" sortable min-width="125px" prop="po_qty">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('unit_of_measure')" v-if="checked.uom" sortable min-width="125px" prop="uom">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('manufacturer')" v-if="checked.manufacturer" sortable min-width="175px" prop="manufacturer">
                                            
                                        </el-table-column>
                                        <el-table-column :label="tt('gr')" v-if="checked.gr_date" sortable min-width="125px" prop="gr_date">
                                            
                                        </el-table-column>
                                    </el-table>
                                    <div class="text-center" style="height: 100px" v-else>
                                        <br><br>
                                        <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                                        <p class="text-muted">{{ tt('empty') }}</p>
                                    </div>
                                </div>
                                <div class="card-footer pb-0 ">   
                                        <span class="float-left">
                                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                        </span>                             
                                        <span class="float-right">
                                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.page.total}) }}
                                        </span>
                                </div>
                            </div>
                            <skeleton-loading v-else></skeleton-loading>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>
</template>


<script>
import Api from '@/helpers/api';
import TransactionHistoryService from '@/services/rcs/transactionHistory.service'
export default {
    data(){
        return{
            table:{
                data:[],
                page:{},
                load:false,
            },
            load:{
                transactionHistory:false,
            },
            loadTable:{
                transactionHistory:false,
            },
            checked:{
                material_number:true,
                plant:true,
                site:true,
                purchase_requisition:true,
                pr_date:true,
                purchase_order:true,
                purchase_order:true,
                pd_date:true,
                vendor:true,
                material_description:true,
                short_description:true,
                unit_price:true,
                currency_pr:true,
                unit_price_po:true,
                currency_po:true,
                po_qty:true,
                uom:true,
                manufacturer:true,
                gr_date:true,
            }
        }
    },
    mounted(){
        this.getTransactionHistory()
    },
    methods:{
        getTransactionHistory(page = null){
            let context = this;
            this.table.load = true;
            Api(context, TransactionHistoryService.getTransactionHistory(page)).onSuccess((response) => {
                context.table.data = response.data.data.data;
                context.table.page = response.data.data;
            }).onError((err) =>{
                context.table.data = [];
                context.table.page = [];
            }).onFinish(() =>{
                context.table.load = false
            }).call()
        },
        pivot(){
            this.$router.push({ name : 'Transaction History Pivot'})
        },
        changePage(page){
            this.getTransactionHistory(page)
        }
    }
}
</script>